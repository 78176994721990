import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import './App.css';
import { ValueCard } from './ValueCard';

// @ts-ignore
import { getVersion } from '@fcqcarryout/project-build-version/frontend';

function App() {

  const [version, setVersion] = useState<any>("N/A");

  useEffect(() => {
    async function loadVersion() {
      setVersion(await getVersion());
    }
    loadVersion();
  }, [setVersion]);

  return (
    <>
      <br />
      <Container fluid>
        <Card bg="dark" text="white">
          <Card.Body>
            <h3>
              oath2 url viewer
            </h3>
            <div style={{ height: "0px", marginTop: "-25px" }} className="float-end text-muted fw-light">
              <small><small>current-version: {version}</small></small>
            </div>
          </Card.Body>
        </Card>
      </Container>
      <br />
      <Container fluid>
        <Row>
          <Col xs={0} md={2} xl={2}>

            <br />
          </Col>
          <Col xs={0} md={7} xl={8}>
            {((window.location.href).replace(window.location.origin, "").length < 2) ?
              <Alert variant="danger">
                <b>no parameter given</b>
              </Alert>
              :
              <ValueCard />
            }
            <br />
          </Col>
          <Col xs={0} md={3} xl={2}>

            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
