import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import * as queryString from 'query-string';

export function ValueCard() {

  const [data, setData] = useState<any>({});

  useEffect(() => {

    var input = (window.location.href).replace(window.location.origin, "");

    console.log(input);

    if (input.charAt(0) === '/') input = input.replace("/", "");
    if (input.charAt(0) === '#') input = input.replace("#", "");

    setData(queryString.parse(input));

  }, []);

  return (
    <>
      <Card bg="dark" text="white">
        <Card.Body>
          {Object.keys(data).map((key) => {
            return (
              <div key={key}>
                <b>{key + ": "}</b>{data[key]}
                <br />
                {(data[key].length > 100) ? <br /> : <></>}
              </div>
            );
          })}
        </Card.Body>
      </Card>
    </>
  );
}

